.contentTag-header {
  display: flex;
  width: 100%;
  background: white;
  flex-direction: row;
}

.new-contentTag-div {
  width: 250px !important;
  margin-left: 40px !important;
  padding-top: 20px !important;
}

.new-contentTag-btn {
  height: 55px !important;
  font-size: 10px !important;
  width: 100% !important;
  margin-bottom: 20px !important;
}

.validation-input {
  display: inline-block !important;
  width: 30% !important;
  margin-left: 20px !important;
}

.reqContext-box {
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: row !important;
  /* justify-content: center !important; */
  gap: 20px !important;
}

.result-div {
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: row !important;
  gap: 2px !important;
}

.selected-request-context {
  border: 1px solid black !important;
  border-radius: 5px !important;
  padding: 20px !important;
  width: 45% !important;
}

.scroller {
  width: 100% !important;
  height: 100px;
  margin: 0px !important;
  overflow-y: scroll;
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
}

.form-box {
  margin-left: 40px !important;
  width: 45% !important;
  padding: 20px !important;
  border: 1px solid black !important;
  border-radius: 5px !important;
}

.content-tag-validation-submit {
  text-align: center !important;
}

.form-title {
  text-align: center !important;
  font-size: larger !important;
}

.align-center {
  text-align: left !important;
}

.indent {
  margin-left: 1em;
}

.accordion-box {
  margin-top: 40px !important;
  margin-left: 40px !important;
  border: 1px solid black !important;
  border-radius: 5px !important;
  width: 45% !important;
  height: 350px !important;
  white-space: nowrap !important;
  overflow-x: auto !important;
  overflow-y: scroll !important;
  white-space: nowrap !important;
}

.accordion-box-more-info {
  margin-top: 40px !important;
  margin-left: 20px !important;
  border: 1px solid black !important;
  border-radius: 5px !important;
  width: 45% !important;
  height: 350px !important;
  white-space: nowrap !important;
  overflow-x: auto !important;
  overflow-y: scroll !important;
  white-space: nowrap !important;
}

.more-info-btn {
  width: 30% !important;
}

.float-right {
  float: right;
}

.left-spacing {
  margin-left: 10px !important;
}

/* Absolute Center Spinner */
.localLoading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.localLoading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.localLoading:not(:required) {
  /* hide "localLoading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.localLoading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}
