.header-content {
  font-weight: bold;
  margin-top: 20px;
  margin-left: 40px;
  font-size: 24px;
  color: #31d47d;
}

.translation-table {
  margin: 15px 40px;
  padding: 5px;
  color: white;
}

.rt-td > div {
  background: none !important;
  text-align: center;
  font-size: 13px;
  /* padding: 4px; */
  height: 29px !important;
}

.rt-tr {
  height: 40px;
}

.rt-resizable-header-content {
  font-size: 16px;
  color: white;
  opacity: 0.8;
}

.rt-td > div:focus {
  outline: none;
  border-bottom: red 1px solid;
}

.translation-input {
  background: none;
  border-bottom: none;
  font-size: 15px !important;
  font-family: Lato;
  width: 100%;
  color: white;
  padding-left: 15px;
}

.my-table {
  background: #282c33;
}

#select-filter-column-application,
#select-filter-column-locale,
#select-filter-column-sync {
  color: black;
  font-style: normal;
}

.ReactTable .rt-tbody .rt-td, .ReactTable .rt-tbody .rt-tr-group {
  border: none;
}
.ReactTable {
  border-radius:4px;
}
.rt-thead {
  background-color: #313D4D;
  padding:10px;
}
.ReactTable  .rt-td:nth-child(1) , .ReactTable  .rt-th:nth-child(1) {
  max-width:5vw;
  padding:8px;
}
.ReactTable  .rt-td:nth-child(2) , .ReactTable  .rt-th:nth-child(2) {
  max-width:40vw;
  padding:8px;
}
.ReactTable  .rt-td:nth-child(3) , .ReactTable  .rt-th:nth-child(3) {
  max-width:45vw;
  padding:8px;
}
.ReactTable  .rt-td:nth-child(4) , .ReactTable  .rt-th:nth-child(4) {
  max-width:5vw;
  padding:8px;
}

.translation-header {
  display: flex;
  padding: 20px 0px 20px 40px;
  width: 100%;
  background: white;
  flex-direction: row;
  position: fixed;
  z-index: 99;
}

.new-translation-div {
  width: calc(50% * (1/3) - 10px - 1px);
  display: flex;
}

.new-translation-btn {
  height: 45px;
  width: 300px;
  font-size: 14px !important;
  border-radius: 0 !important;
  margin-left:12px;
}

.translation-content {
  padding: 115px 30px 50px 50px;
  background: white;
  overflow: auto;
}

.edit-icon {
  color: white;
  font-size: 15px;
}
.tableSelected {
  background-color: blue;
}
.updated-at {
  font-size: 10px;
  font-style: italic;
}
