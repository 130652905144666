.offer-header {
  display: flex;
  padding: 20px 0px 20px 40px;
  width: 100%;
  background: white;
  flex-direction: row;
  position: fixed;
  z-index: 99;
}

.offer-search {
  width: calc(100% * (1/3) - 10px - 1px);
  margin-right: 40px;
}

.select {
  margin-right: 2rem;
  width: 12rem;
  font-size: 16px;
}

.new-offer-div {
  width: calc(100% * (1/7) - 10px - 1px);
  display: flex;
}

.new-offer-btn {
  height: 35px;
  width: 200px;
  font-size: 14px !important;
}

.offer-sortable-item {
  display: flex;
  flex-wrap: wrap;
  margin-right: 5px;
}

.offer-content {
  padding: 90px 50px 50px 50px;
}

.new-offer-content {
  padding: 90px 15px 15px 55px;
}

.offer-card {
  padding: 0px 5px 10px 5px !important;
}
