.package-tabs-section {
  display: none;
  left: 15%;
  border-top: 1px solid #faa21b;
  border-bottom: 1px solid #faa21b;
  background-color: #282c33;
  height: 869px;
  position: absolute;
  color: #fff;
  margin-top: 119px;
  padding: 30px;
  overflow-y: scroll;
  padding-top: 10px;
  margin-top: 15%;
  z-index: 1;
  width: 84%;
}

/* window.innerWidth > 1440 */
.package-tabs-section-lg {
  width: 85%;
  left: 11%;
  margin-top: 10%;
}

.tgc-tile-selected.card-footer::after {
  border-width: 20px;
  display: block;
  position: relative;
  content: '';
  margin: 0 auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #faa31e transparent transparent transparent;
  z-index: 2;
}

.tgc-tile-selected.card-footer {
  background-color: #faa31e !important;
  border: none !important;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.tgc-tile-selected > .tgc-img:after {
  top: 83px;
  border-width: 43px 120px 0 120px;
  left: 35%;
  margin-left: -40%;
  display: block;
  position: relative;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #faa21b transparent transparent transparent;
  z-index: 1;
}

.tgc-tile-selected {
  background-color: #faa21b;
  height: 558px;
  margin-bottom: 30px;
}

.wrapper {
  float: right;
  align-items: center;
  justify-content: center;
}

.close-button {
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
}

.close-button > div {
  position: relative;
}

.close-button-block {
  width: 40px;
  height: 20px;
  position: relative;
  overflow: hidden;
}

.close-button-block:before, .close-button-block:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: calc(55% - 4px);
  display: block;
  width: 4px;
  height: 15px;
  transform-origin: bottom center;
  background: #faa31e !important;
  transition: all ease-out 280ms;
}

.close-button-block:last-of-type {
  transform: rotate(180deg);
}

.close-button .in .close-button-block:before {
  transition-delay: 280ms;
  transform: translateX(20px) translateY(-20px) rotate(45deg);
}

.close-button .in .close-button-block:after {
  transition-delay: 280ms;
  transform: translateX(-22px) translateY(-22px) rotate(-45deg);
}

.close-button .out {
  position: absolute;
  top: 0;
  left: 0;
}

.close-button .out .close-button-block:before {
  transform: translateX(-5px) translateY(5px) rotate(45deg);
}

.close-button .out .close-button-block:after {
  transform: translateX(5px) translateY(5px) rotate(-45deg);
}

.close-button:hover .in .close-button-block:before {
  transform: translateX(-5px) translateY(5px) rotate(45deg);
}

.close-button:hover .in .close-button-block:after {
  transform: translateX(5px) translateY(5px) rotate(-45deg);
}

.close-button:hover .out .close-button-block:before {
  transform: translateX(-20px) translateY(20px) rotate(45deg);
}

.close-button:hover .out .close-button-block:after {
  transform: translateX(20px) translateY(20px) rotate(-45deg);
}

.block {
  display: block !important;
}

.package-sortable-item {
  display: flex;
  flex-wrap: wrap;
  margin-right: 5px;
}

.si-card-body {
  width: 250px;
  height: 152px;
  background: #5BC6FF;
  color: #282c33;
}

.si-card-footer {
  font-size: 20px;
  color: #282c33;
  padding-top: 14px;
  display: flex;
  align-items: left;
  justify-content: center;
}

.package-header {
  display: flex;
  padding: 20px 167px 20px 40px;
  width: 100%;
  background: white;
  flex-direction: row;
  position: fixed;
  z-index: 99;
}

.classifications-select {
  width: calc(100% * (1/5) - 10px - 1px);
  margin-right: 40px;
  font-size: 16px;
}

.package-type-select {
  width: calc(100% * (1/5) - 10px - 1px);
  margin-right: 40px;
  font-size: 16px;
}

.package-search {
  width: calc(100% * (1/3) - 10px - 1px);
  margin-right: 40px;
}

.new-package-div {
  width: calc(100% * (1/7) - 10px - 1px);
}

.new-package-btn {
  height: 35px !important;
  font-size: 14px !important;
  width: 100% !important;
}

.package-content {
  padding: 90px 15px 15px 55px;
}

.width-100 {
  width: 100% !important;
}

.si-card-md {
  min-width: 293px;
  height: 210px;
}
.si-card-body-md {
  width: 293px;
}

.tile-selected-md {
  margin-bottom: -15px;
}
