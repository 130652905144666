.contentTag-header {
  display: flex;
  width: 100%;
  background: white;
  flex-direction: row;
}

.contentTag-search {
  width: 25% !important;
  margin-left: 40px !important;
  padding-top: 20px !important;
}

.new-contentTag-div {
  width: 250px !important;
  margin-left: 40px !important;
  padding-top: 20px !important;
}

.new-contentTag-btn {
  height: 55px !important;
  font-size: 10px !important;
  width: 100% !important;
  margin-bottom: 20px !important;
}

.ReactVirtualized__Grid__innerScrollContainer {
  margin: 80px !important;
}

.rst__rowLabel {
  text-align: center !important;
  font-size: large !important;
  float: left;
}

.ReactVirtualized__Grid .ReactVirtualized__List .rst__virtualScrollOverride {
  height: 0px !important;
}

.ui.blue.button,
.ui.blue.buttons .button {
  margin-top: 100px !important;
  margin-left: 30px !important;
  margin-right: 10px !important;
}

.ui.label,
.ui.labels .label {
  background-color: white !important;
}

.main-lists {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.list-item {
  display: inline-block;
  margin: 0 16px;
}

.rst__rowContentsDragDisabled {
  border-radius: 20px;
}
