.del-checkbox {
  height: 35px !important;
  margin: 0 !important;
  position: static !important;
}

.scroll-entity-list {
  width: 87% !important;
  height: 350px !important;
  overflow-y: auto !important;
}

.each-row-main-div {
  border-bottom: 1px solid gray !important;
  width: 85% !important;
  margin-left: 20px !important;
}

.each-row-child {
  display: grid !important;
  margin-top: 10px !important;
  padding-bottom: 10px !important;
  display: grid !important;
  grid-template-columns: 20% 30% 50% !important;
  margin-bottom: 10px !important;
  overflow: hidden !important;
}

.del-checkbox:hover {
  cursor: pointer !important;
}

.card-header-details {
  float: right !important;
  margin: 0px 0px 0px 20px !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  border: 1px solid rgba(0, 0, 0, 0.7) !important;
  border-radius: 50px !important;
  text-align: center !important;
  color: white !important;
  padding: 5px !important;
}
