.icon-disabled:before {
  opacity: 0.6;
  cursor: not-allowed;
}

/* .package-tabs {
  margin-top: 40px;
} */

.modal-dialog {
  max-width: 9999px !important;
  width: 90% !important;
  height: 50% !important;
}

.modal-content {
  background: #282c33 !important;
  height: 93vh !important;
  overflow-y: scroll !important;
}

.card, .card-footer {
  border: none !important;
}

.modal-header {
  border-bottom: none !important;
}

.tabs-card-footer {
  background-color: #46464C !important;
  border-color: #46464C !important;
}
.text-edit {
  cursor: pointer;
}
.text-green {
  color: greenyellow !important;
}
.text-red {
  color: #ff1660e3 !important;
}
