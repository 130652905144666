input {
  border-bottom: none !important;
  flex-shrink: 1!important;
}
input:focus {
  border-bottom: 1px solid #ccc !important;
}
.slinding-pane {
  width: 600px !important;
}
.slinding-pane-full {
  width: 100vw!important;
}
.expand-widget {
  position: absolute;
  float:right;
  right: 10px;
  top:5px;
  color: black;
}
input:placeholder-shown + label {
  color:#909090 !important; 
  padding-bottom:10px!important;
  font-size: 14px!important;
}
.translationValue {
  font-size: 13px !important;
  border: none !important;
}
.translationValue:focus {
  border: 1px dotted black !important;
}
.form-group label {
  color: #faa31e !important;
}
.saveTranslationsBtn {
  margin-left: 10px;
}
form h4 {
  margin-left: 10px;
}
.updated-at {
  font-size: 13px !important;
  color: #eb9e27;
}

.blink {
  animation: blinker 1s step-start infinite;
  color: red;
  font-style: italic;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
