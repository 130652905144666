form.login-form {
	font-family: 'Open Sans', sans-serif;
	width: 400px;
	margin: auto;
}

div.white-box {
	background: white;
	padding: 4px 0px;
	border-radius: 2px;
  margin-top:4%;
}

form.login-form input[type='text'], input[type='email'], form.login-form input[type='password'] {
	border: none;
	font-size: 18px;
	width: 100%;
	padding: 15px;
  box-sizing: border-box
}

form.login-form input[type='email'], form.login-form input[type='text'] {
	border-bottom: 1px #DDD solid;
}

form.login-form input[type='submit'] {
	margin: 15px 0px;
	border: none;
	font-size: 24px;
	font-weight: 600;
	width: 100%;
	background: #faa31e;
  font-family: Lato;
	color: #FFFFFF;
	padding: 15px;
	border-radius: 2px;
	cursor: pointer;
	transition: background 0.2s;
}

form.login-form input[type='submit']:active {
  color: #DDDDDD;
	background: #386D8C;
	transition: background 0.2s;
}

form.login-form p {
	text-align: center;
}

form.login-form p a {
	color: #FFFFFF;
	text-decoration: none;
	font-size: 18px;
	text-align: center;
}

form.login-form p a:hover {
	text-decoration: underline;
}
form.login-form .logoImage {
  width: 400px !important;
  text-align: center;
}

input::placeholder {
  font-family: Lato;
  opacity: 1 !important;
}
