.extra-package-accordian-title {
  font-size: 15px;
  color: #faa31e;
  cursor: pointer;
}
.extra-packages .card {
  border-bottom: 1px solid #eee !important;
  border-left: 1px solid #eee !important;
  border-right: 1px solid #eee !important;
}
.extra-packages .card-header {
  background: rgba(0, 0, 0, 0.7);
}
.float-right {
  float: right;
}
.text-red {
  color: #f85085e3 !important;
  padding-left: 20px;
}
.text-green {
  color: greenyellow !important;
  padding-left: 20px;
}
.check-css {
  width: 100%;
  margin-left: 10px;
  color: #faa31e;
}
