.modal-content {
  width: 50% !important;
  margin: auto;
  height: 35% !important;
}

.modal-title {
  color: white;
  font-weight: bold !important;
  font-size: 2rem !important;
}
.modal-body {
  color: white;
  font-size: 1.5rem !important;;
}

.btn {
  width: 15%;
  font-size: 15px !important;
}