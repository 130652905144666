.header-content {
  font-weight: bold;
  margin-top: 20px;
  margin-left: 40px;
  font-size: 24px;
  color: #31d47d;
}
.table {
  border-radius:4px;
  overflow: auto;
}
.table thead {
  font-size: 16px;
  color: white;
  background-color:rgb(49, 61, 77);
  border-color: rgb(49,61,77);
}
.table thead tr th {
  color: white;
  border-color:rgb(49, 61, 77);
  vertical-align: text-top;
}
.table thead tr th .filter {
  margin: 32px 0 0 0;
}
.table tbody tr td {
  padding-left: 15px;
  font-size: 16px;
  color: white;
  background: rgb(40, 44, 51);
  border-color: rgb(40, 44, 51);
}

.react-bootstrap-table>table>thead>tr>th .filter {
  font-size: 16px;
  color: black;
}
.react-bootstrap-table>table>thead>tr>th .filter-input
{
  font-size: 16px;
  color: white;
  border-color: rgb(40, 44, 51);
}
#select-filter-column-application
#select-filter-column-locale
#select-filter-column-sync {
  color: black;
  font-style: normal;
}
.translation-table {
  margin: 15px 40px;
  padding: 5px;
  color: white;
}
.translation-input {
  background: none;
  border-bottom: none;
  font-size: 15px !important;
  font-family: Lato;
  width: 100%;
  color: white;
  padding-left: 15px;
}
.my-table {
  background: #282c33;
}
.page-link {
  font-size: 16px;
}
#pageDropDown {
  font-size: 16px;
}
.env-display {
  font-size: 15px !important;
  line-height: 12px;
  color:red;
}
.dropdown-item {
  font-size: 16px;
}
li a {
  display:block;
}
.translation-header {
  display: flex;
  padding: 20px 0px 20px 40px;
  width: 100%;
  background: white;
  flex-direction: row;
  position: fixed;
  z-index: 99;
}
.translation-content {
  padding: 115px 30px 50px 50px;
  background-color: white;
  overflow: auto;
}
.new-translation-div {
  width: calc(100% * (1/7) - 10px - 1px);
  display: flex;
}
.new-translation-btn {
  height: 35px;
  width: 200px;
  font-size: 14px !important;
}
.edit-icon {
  color: white;
  font-size: 15px;
}
.env-indication-btn {
  background-color: grey;
  border:2px solid grey;
  color: white;
  border-radius: 5px;
  padding:0px 15px;
  margin: 1px 10px;
}
.grey {
  border-color:#faa31e;
  background-color: transparent;
  color: #faa31e;
}
.env-display {
  font-size: 15px !important;
  line-height: 12px;
  color:red;
}
.down {
  background-color: red !important;
  color:#FFF !important;
  border-color:red !important;
}
.search-section {
  position: relative !important;
  text-align: right !important;
  margin-right: 40px;
  width: calc(100% * (1/3) - 10px - 1px);
}


/* Absolute Center Spinner */
.localLoading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.localLoading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.localLoading:not(:required) {
  /* hide "localLoading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.localLoading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.black {
  color: black;
}
.refresh {
  font-size: 20px;
  width: 60px;
  margin: 3px 0;
  padding: 4px;
}
