.notification-container{
    position:fixed;
    top:0;
    right:0;
    z-index:999999;
    width:320px;
    padding:0 15px;
    max-height:calc(100% - 30px);
    overflow-x:hidden;
    overflow-y:auto
}
.notification,.notification-container{
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.notification{
    padding:15px 15px 15px 58px;
    border-radius:2px;
    color:#fff;
    background-color:#ccc;
    -webkit-box-shadow:0 0 12px #999;
    box-shadow:0 0 12px #999;
    cursor:pointer;
    font-size:1em;
    line-height:1.2em;
    position:relative;
    opacity:.9;
    margin-top:15px
}
.notification .title{
    font-size:1em;
    line-height:1.2em;
    font-weight:700;
    margin:0 0 5px
}
.notification:focus,.notification:hover{
    opacity:1
}
.notification-enter{
    visibility:hidden;
    -webkit-transform:translate3d(100%,0,0);
    transform:translate3d(100%,0,0)
}
.notification-enter.notification-enter-active{
    -webkit-transition:all .4s;
    transition:all .4s
}
.notification-enter.notification-enter-active,.notification-leave{
    visibility:visible;
    -webkit-transform:translateZ(0);
    transform:translateZ(0)
}
.notification-leave.notification-leave-active{
    visibility:hidden;
    -webkit-transform:translate3d(100%,0,0);
    transform:translate3d(100%,0,0);
    -webkit-transition:all .4s;
    transition:all .4s
}
.notification-icon{
    position:absolute;
    top:50%;
    left:15px;
    margin-top:-14px;
    display:block;
    width:28px;
    height:28px;
    font-size:28px;
    text-align:center;
    line-height:28px
}
.message{
    font-size:1.5rem;
    line-height:2rem;
}
.notification-info{
    background-color:#2f96b4
}
.notification-success{
    background-color:#51a351
}
.notification-warning{
    background-color:#f89406
}
.notification-error{
    background-color:#bd362f
}
