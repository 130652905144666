.header-class {
  margin-top: 10px !important;
  background: rgba(0, 0, 0, 0.7) !important;
  height: 50px !important;
  width: auto;
  border: 1px rgba(0, 0, 0, 0.7) solid !important;
  border-radius: 50px !important;
}

.set-input-width {
  padding-left: 10px;
  float: right !important;
  width: 20% !important;
}

.set-display {
  display: inline !important;
}

.float-right {
  margin-top: 10px;
  float: right !important;
}

.set-display > Input {
  margin-top: 10px !important;
}

.set-display-header {
  display: inline !important;
}

.note-section {
  font-size: larger !important;
  font-weight: bolder !important;
  overflow-wrap: break-word !important;
}
