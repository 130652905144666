.partnerPlatform-header {
  display: flex;
  padding: 20px 0px 20px 40px;
  width: 100%;
  background: white;
  flex-direction: row;
  position: fixed;
  z-index: 99;
}

.new-partnerPlatform-btn {
  height: 35px !important;
  font-size: 14px !important;
  width: 100% !important;
}

.partnerPlatform-search {
  width: calc(100% * (1 / 3) - 10px - 1px);
  margin-right: 40px;
}

.new-partnerPlatform-div {
  width: calc(100% * (1 / 7) - 10px - 1px);
  display: flex;
}

.new-partnerPlatform-btn {
  height: 35px;
  width: 200px;
  font-size: 14px !important;
}

.partnerPlatform-content {
  padding: 90px 50px 50px 50px;
}

.new-partnerPlatform-content {
  padding: 90px 15px 15px 55px;
}

.partnerPlatform-card {
  padding: 0px 5px 10px 5px !important;
}

.si-card-footer {
  font-size: 20px;
  color: #282c33;
  padding-top: 14px;
  display: flex;
  align-items: left;
  justify-content: center;
}
