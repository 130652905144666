@charset "UTF-8";
html {
  font-family: "Lato", sans-serif !important;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  /* min-width: 320px; */
}

html,
body {
  min-height: 100%;
  /* min-width: 320px; */
}

body {
  font-family: "Lato", sans-serif !important;
  margin: 0;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
  scrollbar-face-color: #d9d9d9;
  scrollbar-track-color: transparent;
}

/* body::before {
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f2f2f2;
  /* background: url("../images/bg-img.jpg") no-repeat center center;
  background-size: cover;
  will-change: transform;
  z-index: -1;
} */

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

b,
strong {
  font-weight: bold;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

img {
  border: 0;
}

button,
input,
select,
textarea {
  color: none;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
  font-size: 13px !important;
  border: 0;
  font-family: "Lato";
  font-weight: 400;
  letter-spacing: 0.9px;
  color: #282c33;
  border-bottom: 1px solid #ccc;
  font-family: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0;
  cursor: text;
}

input[type="text"]:focus {
  outline: 0;
  border-bottom: 1px solid #666;
  height: 30px;
}
input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.field select {
  height: 35px !important;
  border-radius: 18px !important;
  font-size: 14px !important;
  width: 60%;
}
.field select:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

textarea {
  overflow: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #23527c;
  text-decoration: underline;
}

a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.nav > li {
  position: relative;
  display: block;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
}

.nav > li.disabled > a {
  color: #777;
}

.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
  color: #777;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  border-color: #337ab7;
}

.nav > li > a > img {
  max-width: none;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
  width: 100px;
}

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #555;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}

.nav-tabs.nav-justified {
  width: 100%;
  border-bottom: 0;
}

.nav-tabs.nav-justified > li {
  float: none;
}

.nav-tabs.nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px;
}

.nav-tabs .nav-item {
  width: 200px;
  /* border-right: 2px solid #e7ecea; */
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-left-50 {
  margin-left: 50px;
}

.margin-left-none {
  margin-left: 0px !important;
}

textarea:focus,
input:focus {
  outline: none;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.width-50 {
  width: 50px;
}

button.btn.btn-primary {
  background: #faa31e;
  border: none;
  border-radius: 36px;
}
button.btn.btn-danger {
  border: none;
  border-radius: 36px;
}

button.btn.btn-primary.disabled,
button.btn.btn-primary[disabled],
fieldset[disabled] button.btn.btn-primary,
button.btn.btn-primary.disabled:hover,
button.btn.btn-primary[disabled]:hover,
fieldset[disabled] button.btn.btn-primary:hover,
button.btn.btn-primary.disabled:focus,
button.btn.btn-primary[disabled]:focus,
fieldset[disabled] button.btn.btn-primary:focus,
button.btn.btn-primary.disabled.focus,
button.btn.btn-primary[disabled].focus,
fieldset[disabled] button.btn.btn-primary.focus,
button.btn.btn-primary.disabled:active,
button.btn.btn-primary[disabled]:active,
fieldset[disabled] button.btn.btn-primary:active,
button.btn.btn-primary.disabled.active,
button.btn.btn-primary[disabled].active,
fieldset[disabled] button.btn.btn-primary.active {
  background: #faa31e;
  border-color: #faa31e;
}

button.btn.btn-primary.disabled:hover,
button.btn.btn-primary[disabled]:hover,
fieldset[disabled] button.btn.btn-primary:hover,
button.btn.btn-primary.disabled:hover:hover,
button.btn.btn-primary[disabled]:hover:hover,
fieldset[disabled] button.btn.btn-primary:hover:hover,
button.btn.btn-primary.disabled:focus:hover,
button.btn.btn-primary[disabled]:focus:hover,
fieldset[disabled] button.btn.btn-primary:focus:hover,
button.btn.btn-primary.disabled.focus:hover,
button.btn.btn-primary[disabled].focus:hover,
fieldset[disabled] button.btn.btn-primary.focus:hover,
button.btn.btn-primary.disabled:active:hover,
button.btn.btn-primary[disabled]:active:hover,
fieldset[disabled] button.btn.btn-primary:active:hover,
button.btn.btn-primary.disabled.active:hover,
button.btn.btn-primary[disabled].active:hover,
fieldset[disabled] button.btn.btn-primary.active:hover {
  transform: none;
}

button.btn.btn-primary:hover,
button.btn.btn-primary:focus,
button.btn.btn-primary.focus,
button.btn.btn-primary:active,
button.btn.btn-primary.active {
  background: #faa31e;
  border-color: #088679;
}

button.btn.btn-primary:active,
button.btn.btn-primary:target {
  background-color: #1b867b;
}

.submit-btn {
  min-width: 244px;
  height: 50px;
  font-size: 1.5rem;
  background: rgba(219, 21, 99, 1);
  background: -webkit-linear-gradient(45deg, #d5007d, #e53935);
  background: -o-linear-gradient(45deg, #d5007d, #e53935);
  background: -moz-linear-gradient(45deg, #d5007d, #e53935);
  background: linear-gradient(45deg, #d5007d, #e53935);
  border: none !important;
}

.label {
  border-radius: 0;
}

.label-primary {
  background: #faa31e;
}

.label-info {
  background: #63bbb2;
}

.label-success {
  background: #90b900;
}

.label-warning {
  background: #dfb81c;
}

.label-danger {
  background: #e85656;
}

.form-horizontal label {
  line-height: 34px;
  margin-bottom: 0;
  padding-top: 0 !important;
}

.form-group label {
  /* margin-bottom: 5px; */
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  color: #282c33;
}
.select-label {
  color: #faa31e !important;
}
.form-control {
  color: #666666;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  color: #666666;
  opacity: 0.7;
}

.form-control:-moz-placeholder {
  color: #666666;
  opacity: 0.7;
}

.form-control::-moz-placeholder {
  color: #666666;
  opacity: 0.7;
}

.form-control:-ms-input-placeholder {
  color: #666666;
  opacity: 0.7;
}

.form-control:focus {
  box-shadow: none;
  border-color: #4db1a7;
  background: #ffffff;
}

select.form-control {
  padding-left: 8px;
}

textarea.form-control {
  height: 96px;
}

.form-inline .form-group input {
  width: 100%;
}

.form-inline .form-group label {
  margin-right: 12px;
}

.form-inline button[type="submit"] {
  margin-left: 12px;
}

.form-inline label.custom-checkbox > span,
.form-inline label.custom-radio > span {
  display: block;
  margin-top: -13px;
  margin-right: 10px;
}

.disabled {
  color: #dddddd;
  cursor: not-allowed;
}

.disabled:before {
  border-color: #dddddd !important;
  cursor: not-allowed;
}

.input-group {
  width: 100%;
  margin-bottom: 15px;
}

.input-group > span {
  border-radius: 0;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem !important;
  -webkit-appearance: checkbox;
}

.field {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 1em;
}

label,
input {
  transition: all 0.2s;
  touch-action: manipulation;
}

label {
  letter-spacing: 0.05em;
}

input:placeholder-shown + label {
  cursor: text;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(0, 1.5rem) scale(1);
  text-transform: none;
  font-size: 16px;
  /* color:#909090; */
  color: white;
}

::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit;
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
  color: transparent;
}

input:not(:placeholder-shown) + label,
input:focus + label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
  color: #faa31e;
  font-size: 14px;
}

.nowrap {
  white-space: nowrap;
}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Source Sans Pro", sans-serif !important;
  width: 100%;
  margin-top: 0;
}

h1.color,
h2.color,
h3.color,
h4.color,
h5.color,
h6.color {
  color: #e85656;
}

body a {
  color: #faa31e;
  text-decoration: none !important;
  transition: color 0.2s ease;
}

body a:hover {
  color: #1b867b;
}

.panel {
  color: #666666;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  position: relative;
  margin-bottom: 24px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
}

.panel.panel-blur {
  background: url("../images/bg-img-blurred.jpg");
  transition: none;
  background-attachment: fixed;
}

.nav-tabs > li.with-dropdown > a {
  padding: 0;
}

.nav-tabs > li.with-dropdown .dropdown-toggle {
  padding: 10px 15px;
  display: inline-block;
  cursor: pointer;
}

.nav.nav-tabs {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.01);
  /* background-color: white; */
}

.nav.nav-tabs a {
  color: #ffffff;
}

.nav.nav-tabs a:hover {
  color: #ffffff;
}

.nav.nav-tabs > li > a {
  margin-right: 0;
  margin-bottom: 0;
  border-radius: 0;
  border: none;
  color: white;
  text-align: center;
  font-size: 1.4em;
  font-weight: 700;
  text-transform: capitalize;
}

.nav.nav-tabs > li > a:hover {
  border: none;
  color: #74777b;
  cursor: pointer;
}

.nav.nav-tabs > li.active > a {
  color: #5bc6ff;
  border-bottom: 2px solid #5bc6ff;
  background: none;
}

.nav.nav-tabs .dropdown-menu > li > a {
  color: #666666;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
}

.nav-tabs > li.active > a,
.nav-tabs > .nav-tabs > li.active > a:focus {
  border: none;
}

.content-top {
  padding-top: 13px;
  padding-bottom: 27px;
}

.page-top {
  background: rgba(0, 0, 0, 1);
  position: fixed;
  z-index: 904;
  box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.5);
  height: 66px;
  width: 100%;
  min-width: 320px;
}

.page-top.scrolled {
  background: rgba(0, 0, 0, 0.85);
}

a.al-logo {
  margin: 5% auto 5% 10%;
  width: 130px;
  height: auto;
}

.profile {
  height: 100%;
  width: 100%;
}

a.al-logo span {
  color: #faa31e;
}

.user-profile {
  float: right;
  margin-top: 10px;
}

.main {
  margin-left: 180px;
  padding: 0 0 34px 0;
  /* min-height: 500px; */
  height: 100vh;
}

.login-page {
  background: #282c33;
  display: flex;
  flex-direction: column;
}

.content {
  padding: 25px 32px 8px 40px;
}

.al-sidebar {
  width: 200px;
  /* top: 66px; */
  left: 0;
  z-index: 904;
  display: block;
  min-height: 100%;
  /* background: #141721; */
  background: #282c33;
  height: 100%;
  position: fixed;
}

.al-sidebar-list-item {
  display: block;
  position: relative;
  float: none;
  padding: 0;
}

.al-sidebar-list-item.selected:not(.with-sub-menu) {
  background-color: #faa31e;
}

.al-sidebar-list-item.selected:not(.with-sub-menu) a.al-sidebar-list-link {
  color: #ffffff;
}

.al-sidebar-list-item.selected:not(.with-sub-menu) a.al-sidebar-list-link b {
  color: #ffffff;
}

a.al-sidebar-list-link {
  display: block;
  height: 42px;
  padding-left: 18px;
  text-shadow: none;
  font-size: 16px;
  text-decoration: none;
  color: #ffffff;
  line-height: 42px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

a.al-sidebar-list-link:hover {
  color: #faa31e;
}

a.al-sidebar-list-link:hover b {
  color: #faa31e;
}

a.al-sidebar-list-link i {
  margin-right: 18px;
  width: 16px;
  display: inline-block;
}

a.al-sidebar-list-link b {
  display: block;
  opacity: 1;
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-shadow: none;
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 12px;
  padding: 0;
  text-align: center;
  color: #cccccc;
}

input.search-input {
  margin-left: -8px;
  padding-left: 8px;
}

.slinding-pane {
  width: 40%;
}

.slide-pane__overlay {
  z-index: 999;
}

.card-body {
  padding: 2rem !important;
}

.card-footer {
  padding: 0 !important;
}

.card-footer-color {
  background-color: #5bc6ff !important;
  border-color: #5bc6ff !important;
}

i:hover {
  cursor: pointer;
}

.card-body:hover {
  cursor: pointer;
}

.ham-burger {
  font-size: 25px;
  padding: 20px;
  color: #15aabf;
  cursor: pointer;
}

.search-section {
  position: absolute;
  text-align: center;
}

.search-section:before,
.search-section:after {
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  margin: 0 0 0 -400px;
  position: absolute;
  width: 800px;
}

/* .search-section form {
	display: inline-block;
	font-size: 0px;
	padding: 5px;
	position: relative;
	z-index: 1;
} */

.search-section input {
  background: #fff;
  border: 1px solid #d9d9d9;
  color: #888;
  display: block;
  float: left;
  font-family: "Lato", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  height: 35px;
  margin: 0;
  padding: 5px;
  /* text-shadow: 0 -1px 0 #000; */
  width: 100%;
  color: #3e3e3e;
  outline: none;
  text-indent: 25px;
  box-shadow: 0 1px 0 #eee;
  border-radius: 4px;
}

.search-section button {
  background: #222;
  background: linear-gradient(#333, #222);
  box-sizing: border-box;
  border: 1px solid #444;
  border-left-color: #000;
  border-radius: 0 5px 5px 0;
  box-shadow: 0 2px 0 #000;
  color: #fff;
  display: block;
  float: left;
  font-family: "Lato", sans-serif !important;
  font-size: 13px;
  font-weight: 400;
  height: 40px;
  line-height: 40px;
  margin: 0;
  padding: 0;
  position: relative;
  text-shadow: 0 -1px 0 #000;
  width: 80px;
}

.search-section button:hover,
.search-section button:focus {
  background: #292929;
  background: linear-gradient(#393939, #292929);
  color: #5f5;
  outline: none;
}

.search-section button:active {
  background: #292929;
  background: linear-gradient(#393939, #292929);
  box-shadow: 0 1px 0 #000, inset 1px 0 1px #222;
  top: 1px;
}

@keyframes glow {
  0% {
    border-color: #393;
    box-shadow: 0 0 5px rgba(0, 255, 0, 0.2), inset 0 0 5px rgba(0, 255, 0, 0.1),
      0 2px 0 #000;
  }
  100% {
    border-color: #6f6;
    box-shadow: 0 0 20px rgba(0, 255, 0, 0.6),
      inset 0 0 10px rgba(0, 255, 0, 0.4), 0 2px 0 #000;
  }
}

/* New Home page */
/**************************
    SCSS VARIABLES
**************************/
@-webkit-keyframes blink {
  0% {
    color: RGB(235, 33, 46);
  }
  25% {
    color: white;
  }
}
@keyframes blink {
  0% {
    color: RGB(235, 33, 46);
  }
  25% {
    color: white;
  }
}
/**************************
    HELPER DECLARATIONS
**************************/
html *,
html *:after,
html *:before {
  box-sizing: border-box;
}

h1,
h2,
h3 {
  font-family: "ff-dax-web-pro", "Lato", sans-serif;
}

h1 {
  font-size: 4em;
  letter-spacing: -0.05em;
}

h2,
h3 {
  font-size: 2em;
  font-weight: normal;
}

a {
  color: white;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  font-weight: bolder;
  color: RGB(133, 189, 173);
}
a:hover span {
  color: white;
}

/**************************
    LAYOUT CLASSES
**************************/
.l-wrap {
  position: relative;
  /* max-width: 968px; */
  margin: auto;
  width: 100vw;
  height: 100vh;
  font-family: "Lato", sans-serif;
}

.l-wrap-abs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.l-row {
  position: relative;
  padding: 0;
  margin: 0 auto;
  /* max-width: 968px; */
}

[class*="l-col-"] {
  float: left;
  position: relative;
}

.l-col-2 {
  width: 16.66666667%;
}

.l-col-4 {
  width: 33.33333333%;
}

.l-col-5 {
  width: 41.66666666%;
}

/**************************
    MODULE CLASSES
**************************/
.m-overlay {
  display: none;
  position: absolute;
  top: 170px;
  left: 10px;
  bottom: 80px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  border-radius: 10px;
  padding: 50px 100px;
  font-size: 130%;
}

.is-blur {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.m-header {
  text-align: center;
  padding: 25px 0;
}

.m-icon {
  position: relative;
  width: 175px;
  height: 175px;
  border-radius: 50%;
  margin: auto;
  border: 2px RGB(192, 192, 192) solid;
}
.m-icon span {
  display: block;
  position: absolute;
  text-align: center;
  transition: all 300ms ease-in-out;
}
.m-icon:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  box-shadow: 0px -1px 15px rgba(0, 0, 0, 0.7);
  border: 0px RGB(192, 192, 192) solid;
}
.m-icon:hover span {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.m-icon:hover:active {
  transition: none;
  border-color: RGB(192, 192, 192) !important;
  background-color: RGB(192, 192, 192) !important;
  opacity: 0.3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.7),
    inset 0 -1px 1px rgba(255, 255, 255, 0.7);
  /*top internal highlight*/
}
.m-icon:hover:active span {
  transition: none;
  color: RGB(0, 121, 193) !important;
}
.l-section-main {
  margin-top: 10%;
}

#js-icon-1 span {
  width: 84px;
  height: 84px;
  top: 50%;
  left: 50%;
  margin-left: -42px;
  margin-top: -20px;
}
#js-icon-1 span {
  color: white;
}
#js-icon-1:hover {
  border-color: #faa31e;
  background-color: #faa31e;
}

#js-icon-2 {
  margin-top: 25px;
  width: 218.75px;
  height: 218.75px;
}
#js-icon-2 span {
  width: 84px;
  height: 84px;
  top: 50%;
  left: 50%;
  margin-left: -42px;
  margin-top: -20px;
}
#js-icon-2:hover {
  border-color: #faa31e;
  background-color: #faa31e;
}
#js-icon-2:hover span {
  color: white;
}

#js-icon-3 span {
  width: 84px;
  height: 84px;
  top: 50%;
  left: 50%;
  margin-left: -42px;
  margin-top: -20px;
}
#js-icon-3:hover {
  border-color: #faa31e;
  background-color: #faa31e;
}
#js-icon-3:hover span {
  color: white;
}

.m-sub-header {
  padding: 10px 10% 0;
  text-align: center;
  font-size: 18px;
}

.m-output {
  height: 200px;
  overflow: hidden;
}

.m-blurb {
  width: 80%;
  max-width: 600px;
  margin: 40px auto 50px;
  text-align: center;
  font-size: 1.25em;
  position: absolute;
  left: 0;
  right: 0;
}

#js-blurb-4 {
  transition: all 400ms ease-in-out 200ms !important;
}

.m-quote {
  text-align: left;
  border-left: 2px RGB(192, 192, 192) solid;
  border-right: 2px RGB(192, 192, 192) solid;
}
.m-quote span {
  display: block;
  text-align: right;
  font-weight: bold;
}

.m-footer {
  padding: 25px;
}

.m-right ul,
.m-left ul {
  margin: 0;
  padding: 0;
  position: relative;
  list-style-type: none;
}
.m-right ul li,
.m-left ul li {
  display: block;
  width: 100px;
  text-align: center;
}

.m-right li {
  float: right;
}

.m-left li {
  float: left;
}

#js-overlay {
  cursor: pointer;
  -webkit-animation: blink 4s infinite 3s;
  animation: blink 4s infinite 3s;
}

/**************************
    STATE CLASSES
**************************/
.is-hidden {
  opacity: 0;
  transition: all 300ms ease-in-out 200ms;
  -webkit-transform: translate(-300px, 0px);
  transform: translate(-300px, 0px);
}

.is-visible {
  opacity: 1;
  transition: all 400ms ease-in-out 200ms;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.is-selected {
  text-decoration: underline;
  position: relative;
}
.is-selected:after {
  display: block;
  position: absolute;
  width: 200px;
  height: 20px;
  top: 70px;
  left: -100px;
  margin-left: 50%;
  content: "";
  opacity: 0.5;
  font-style: italic;
}

.is-hovered {
  display: block;
}

/**************************
    THEME CLASSES
**************************/
.t-bgblue {
  background-color: #282c33;
  color: white;
}

/**************************
    HELPER DECLARATIONS
**************************/
.clearfix {
  *zoom: 1;
}

.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* Search */
.aaa:after {
  content: "\f002";
  font-family: "FontAwesome";
  position: absolute;
  top: 5px;
  color: #999999;
  left: 10px;
  font-size: 16px;
}

.slide-pane__title-wrapper {
  margin-left: 0px;
}

.slide-pane__header {
  background: #282c33;
  color: white;
}

.form-checkbox {
  margin-left: 10px;
  cursor: pointer;
}

.form-selectAll {
  margin: auto;
  cursor: pointer;
}

.form-checkbox > .form-check-input {
  margin-top: 5px !important;
  cursor: pointer;
}

.form-checkbox > .form-check-label {
  margin-left: 6px;
}

.form-checkbox > .greyed-form-check-label {
  margin-left: 6px;
  color: #999999 !important;
}

.black {
  color: black !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-18-bold {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #000;
}

.height-48 {
  height: 48px;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none !important;
}

.clearfix {
  display: block;
}

.center-heading {
  font-size: 20px;
}

.pagination {
  margin-top: 2rem;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #faa31e;
  background-color: #282c33;
  border: 1px solid #282c33;
  margin-left: -1px;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #282c33;
  background-color: #faa31e;
  border-color: #faa31e;
  cursor: default;
}
