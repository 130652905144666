.set-align-dropdown {
  margin-bottom: 25px;
  display: flex;
  width: 300px !important;
}

.discard-box {
  margin-left: 15px;
  background: #c00707 !important;
}

.edit-table-data {
  background: #666 !important;
  color: #fff !important;
  border-radius: 6px !important;
  border-color: #faa31e !important;
}

.new-plan-package-div {
  display: inline !important;
}

.new-plan-package-btn {
  height: 35px !important;
  font-size: 14px !important;
  width: 35% !important;
  margin-bottom: 20px !important;
}

.note-section {
  padding-left: 10px;
  font-size: larger !important;
  font-weight: bolder !important;
  overflow-wrap: break-word !important;
}

.set-input-width {
  float: right !important;
  width: 20% !important;
}
